import React, { useRef, useState, useContext } from 'react';
import { Link, useHistory, useLocation  } from 'react-router-dom';

import UserSession from 'components/userSession';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  AppBar, Box, Container, Toolbar,
} from '@material-ui/core';

// import Navigation, {
//   NavigationProvider,
//   MobileMenuToggle,
// } from 'components/navigation';
import GlobalMessages from 'components/GlobalMessages';
import { MenuNode } from '../interfaces';

import Search from './Search';
import MiniCart from './MiniCart';

import Logo from './Logo';
import SoboDrawer from './Sobo/SoboDrawer';
import { useSetPatientChartDisconnectReason } from 'operations/mutations/setPatientChartDisconnectReason';

import { SiteContext } from 'utils/SiteProvider';

interface Props {
  menuJson: MenuNode[] | null;
}

const Header: React.FC<Props> = ({ menuJson }) => {
  const classes = useStyles();

  const history = useHistory();
  const location = useLocation();
  const [searchOpen, setSearchOpen] = useState(location.pathname === '/search');
  const appBar = useRef<any>();

  const { mutate: setPatientChartDisconnectReason } = useSetPatientChartDisconnectReason();
  const site:any = useContext(SiteContext);
  /*
   * When location change close the search box if it is open
   */
  history.listen((_) => {
    if (searchOpen) {
      setSearchOpen(false);
    }
  });

  const toggleSearchMenu = (isOpen: boolean) => setSearchOpen(isOpen);

  const handleLocationChange = () => {
    if(location?.pathname?.includes('/patient/')) {
      setPatientChartDisconnectReason({
        variables: {
          input: {
            accountId: site.session.user?.id,
            code: 'NAVIGATE'
          }
        }
      }).then(() => {
        history.push('/')
      });
    } else {
      history.push('/')
    }
  }

  return (
    <Box style={{ position: 'relative',zIndex:1000 }}>
      <AppBar color="inherit" ref={appBar} className={classes.appBar}>
        <Container maxWidth="xl">
          <Toolbar className={classes.root}>
            <Link to="#" onClick={handleLocationChange} className={classes.logoWrapper} 
                  >
              <Logo />
            </Link>

            <div className={classes.menuIcons}>
              <SoboDrawer/>
              <UserSession />
            </div>
            {/* <NavigationProvider>
              <Navigation />

              <div className={classes.menuIcons}>
                {!searchOpen && <MobileMenuToggle />}
                <Search
                  isOpen={searchOpen}
                  toggleSearchMenu={toggleSearchMenu}
                  enableTypeahead={true}
                />
                {!searchOpen && (
                  <>
                    <UserSession />
                    <MiniCart />
                    
                     TODO: Only checks if users is offline, needs to also check if logged in user has  sobo access 
                    {data?.isLoggedIn ?
                       <SoboDrawer/>
                    :null}
                 
                  </>
                )}
              </div>
            </NavigationProvider> */}

          </Toolbar>
        </Container>
      </AppBar>
      <GlobalMessages />
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    paddingTop: theme.spacing(0.8),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
  },
  logoWrapper: {
    '@media screen and (max-width: 1100px)': {
      flexGrow: 1,
    },
    [theme.breakpoints.down('xs')]: {
      flexBasis: '100%',
    },
  },
  menuIcons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '10px 0',
    '& > *': {
      marginLeft: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-around',
    },
  },
  appBar: {
    backgroundColor: '#19354E',
    borderColor: '#BDBDBD',
    borderWidth: '1px',
    borderStyle: 'solid',
  }
}));

export default Header;
