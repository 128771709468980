import React, {useContext} from 'react';
import { Link } from 'react-router-dom';
// import { useQuery, useMutation } from '@apollo/client';
import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { clearToken, getAuthToken } from 'utils/auth';
import { GET_USER } from 'operations/queries/getUser';
import { user } from 'operations/queries/__generated__/user';
import { SiteContext } from 'utils/SiteProvider';
// import { LOGOUT } from 'operations/mutations/logout';
import { useLogout } from 'operations/mutations/logout';
import { useSetPatientChartDisconnectReason } from 'operations/mutations/setPatientChartDisconnectReason';


// #region Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { EXIT_SOBO_MODE } from 'operations/mutations/setSoboMode';
import { useLocation } from 'react-router-dom';
import { Avatar, ButtonBase, Typography } from '@material-ui/core';


// #endregion Material-UI

const UserSession = () => {
  const classes = useStyles();
  const location = useLocation();
  // const { data, client } = useQuery<user>(GET_USER, {
  //   skip: !getAuthToken(),
  // });
  const [exitSobo] = useMutation<boolean,null>(EXIT_SOBO_MODE)
  //   const [logout] = useMutation(LOGOUT);
  const { mutate: logout } = useLogout();
  const { mutate: setPatientChartDisconnectReason } = useSetPatientChartDisconnectReason();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const site:any = useContext(SiteContext);

  const open = Boolean(anchorEl);

  const handleLogOut = async (
    event: React.MouseEvent<HTMLLIElement | MouseEvent>
  ) => {
    setAnchorEl(null);

    try {
      if(location?.pathname?.includes('/patient/')) {
          try {
            await setPatientChartDisconnectReason({
              variables: {
                input: {
                  accountId: site.session.user?.id,
                  code: 'LOGOUT'
                }
              }
            });
          } finally {
            exitSobo()
            await logout()
            // await client.cache.reset();
            clearToken();
            window.location.href = '/';
          }
          
      } else {
        exitSobo()
        await logout()
      // await client.cache.reset();
        clearToken();
        window.location.href = '/';
      }
      //exitSobo()
      //await logout()
      // await client.cache.reset();
      //clearToken();
      //window.location.href = '/';
    } catch (error) {
      console.log('Unable to clear cache ', error);
      window.location.href = '/';
    }
  };

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {site && site.session.user ? (
        <ButtonBase
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          className={classes.buttonBase}
        >
          <Avatar className={classes.avatar}>
            <AccountCircle className={classes.icon} />
          </Avatar>
          <div className={classes.textContainer}>
            <Typography variant="subtitle1" noWrap>
              {site.session.user.contact?.firstName} {site.session.user.contact?.lastName}
            </Typography>
            <Typography variant="caption" display="block" noWrap>
              {site.session.user.contact?.professionalDesignations}
            </Typography>
          </div>
        </ButtonBase>
      ) : (
        <Button component={Link} to="/login" className={classes.loginButton}>
          Login/Register
        </Button>
      )}

      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        open={open}
        onClose={handleClose}
        getContentAnchorEl={null}
      >
        {/* <MenuItem onClick={handleClose}>
          <Link to="/account" className={classes.menuLink} >
            My Account
          </Link>
        </MenuItem> */}
        <MenuItem onClick={handleLogOut}>
          <Link to="#" className={classes.menuLink}>
            Log Out
          </Link>
        </MenuItem>
      </Menu>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  buttonBase: {
    marginLeft: 'auto',
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    backgroundColor: 'transparent',
    width: '48px',
    height: '48px',
  },
  icon: {
    fontSize: '48px',
  },
  textContainer: {
    textAlign: 'left',
    marginLeft: '10px',
    color: theme.palette.common.white,
  },
  loginButton: {
    marginLeft: 'auto',
  },
  menuLink: {
    color: 'inherit',
    textDecoration: 'none',
  },
}));

export default UserSession;
